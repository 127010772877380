<template>
  <v-footer class="justify-center" color="mainBackground" height="100">
    <div class="title font-weight-light mainTextColor--text text-center">{{ footerText }}</div>
  </v-footer>
</template>

<script>
export default {
  name: 'Footer',
  data: () => ({
    footerText: '© ' + new Date().getFullYear() + ' - The Title Girl'
  })
};
</script>

<style scoped></style>
